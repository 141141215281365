import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col, message, Form, Input, Typography, Select } from 'antd';
import { useQueryParams, StringParam } from 'use-query-params';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import PageTitle from 'components/PageTitle';
import { useProfileTemplate } from 'hooks/profileTemplate';
import { useFetch } from 'services/hooks';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';

import { useOnboardingTemplate } from 'hooks/onboardingTemplate';
import Footer from '../components/Footer';
import CardOnboarding from './components/CardOnboarding';
import CardBackgroundChecking from './components/CardBackgroundChecking';
import CardComplianceRules from './components/CardComplianceRules';
import PriceWidget from '../../components/PriceWidget';

import './styles.less';

const I18N_BASE_PATH =
  'pages.private.profileModels.profileModelCreate.confirmation.index';

const { Title } = Typography;

const { Option } = Select;

const Confirmation = ({ formRef }) => {
  const { t: translate } = useTranslation();

  const history = useHistory();
  const [query, setQuery] = useQueryParams({
    step: StringParam,
    profileType: StringParam,
  });

  const { allOnboardingTemplates } = useOnboardingTemplate();

  const {
    summaryPrices,
    profileTemplate,
    setProfileTemplate,
    product,
  } = useProfileTemplate();
  const { post, loading: loadingSave } = useFetch();

  const buildExtraSettingsValidation = useCallback(
    (
      cnaes,
      cnaesType,
      legalNature,
      legalNatureType,
      requiredFields,
      rule,
      limit,
      timeUnit,
      cpfType,
      cpf,
      invalidRangeAgesList,
      parametersToCompare,
      months,
      _belowScore,
      _overScore,
      _minScore,
      _maxScore,
      onlyQsaCompatible
    ) => {
      if (legalNatureType && rule?.value === 'has_at_least_one_legal_nature')
        return { type: legalNatureType, list: legalNature };
      if (cnaesType && rule?.value === 'has_at_least_one_cnae')
        return { type: cnaesType, list: cnaes };
      if (requiredFields && rule?.value === 'ocr_fields_not_empty')
        return { requiredFields };
      if (limit && timeUnit && rule?.value === 'cnh_has_valid_date')
        return { limit: parseInt(limit, 10), timeUnit };
      if (cpfType && cpf && rule?.value === 'cpf_error_code')
        return { type: cpfType, list: cpf };
      if (rule?.value === 'invalid_range_ages')
        return { invalidRangeAges: invalidRangeAgesList };
      if (
        [
          'ocr_equals_parameters_data',
          'ocr_equals_receita',
          'parameters_equals_receita',
        ].includes(rule?.value)
      )
        return { parametersToCompare };
      if (rule?.value === 'minimum_company_lifetime') return { months };
      if (rule?.value === 'score_check_below') {
        const belowScore = parseInt(_belowScore, 10);
        return { belowScore };
      }
      if (rule?.value === 'score_check_over') {
        const overScore = parseInt(_overScore, 10);
        return { overScore };
      }
      if (rule?.value === 'score_check_between') {
        const minScore = parseInt(_minScore, 10);
        const maxScore = parseInt(_maxScore, 10);
        return {
          minScore,
          maxScore,
        };
      }
      if (rule?.value === 'qsa_consistent') return { onlyQsaCompatible };
      return undefined;
    },
    []
  );
  const buildPayload = useCallback((data) => {
    const {
      rule,
      actionWhenInvalid,
      actionWhenPending,
      customActionWhenInvalid,
      switchCustom,
      cnaesType,
      cnaes,
      legalNature,
      legalNatureType,
      requiredFields,
      limit,
      timeUnit,
      cpfType,
      cpf,
      invalidRangeAgesList,
      parametersToCompare,
      months,
      belowScore,
      overScore,
      minScore,
      maxScore,
      onlyQsaCompatible,
    } = data;
    return {
      rule: rule?.value || rule,
      actionWhenInvalid,
      actionWhenPending: switchCustom ? actionWhenPending || undefined : undefined,
      customActionWhenInvalid: switchCustom
        ? customActionWhenInvalid || undefined
        : undefined,
      extraSettings: switchCustom
        ? buildExtraSettingsValidation(
            cnaes,
            cnaesType,
            legalNature,
            legalNatureType,
            requiredFields,
            rule,
            limit,
            timeUnit,
            cpfType,
            cpf,
            invalidRangeAgesList,
            parametersToCompare,
            months,
            belowScore,
            overScore,
            minScore,
            maxScore,
            onlyQsaCompatible
          )
        : undefined,
    };
  }, []);

  const buildExtraSettings = useCallback(() => {
    const processesKeywordsObject = profileTemplate?.validations?.find(
      ({ rule }) => rule?.key === 'has_processes_keywords'
    );
    return {
      processesWithKeywords:
        processesKeywordsObject && processesKeywordsObject?.switchCustom
          ? {
              searchAllFields: processesKeywordsObject?.searchAllFields,
              archived: processesKeywordsObject?.archived,
              parts: processesKeywordsObject?.parts,
            }
          : undefined,
      alwaysManualReview: profileTemplate?.alwaysManualReview,
    };
  }, [profileTemplate]);

  const save = useCallback(
    async (payload) => {
      try {
        const res = await post({
          url: `/profile-templates`,
          payload,
        });
        setQuery({ step: 'success', profileType: payload.type });
        setProfileTemplate({ _id: res?.id });
      } catch (error) {
        message.error(translate(`${I18N_BASE_PATH}.messages.error.payload`));
      }
    },
    [post]
  );

  const showConfirmModal = useCallback((payload) => {
    confirm({
      title: translate(`${I18N_BASE_PATH}.modalConfirm.title`),
      icon: <ExclamationCircleOutlined />,
      content: translate(`${I18N_BASE_PATH}.modalConfirm.subtitle`),
      onOk() {
        save(payload);
      },
    });
  });

  const onSubmit = useCallback(async () => {
    formRef?.validateFields(['name']);
    if (!profileTemplate.name) {
      return;
    }
    if (profileTemplate?.validations?.length > 0) {
      profileTemplate.validations = profileTemplate?.validations?.filter(
        ({ enabled, actionWhenInvalid }) => actionWhenInvalid || enabled === true
      );
    }

    const profileRules = profileTemplate?.profileRules
      ?.map((rule) => buildPayload(rule))
      ?.filter((pr) => !_.isEmpty(pr) && pr?.rule && pr?.actionWhenInvalid);

    const backgroundChecking = {
      services: Object.keys(profileTemplate?.backgroundCheckingServices || {})
        ?.filter((source) => profileTemplate?.backgroundCheckingServices[source])
        ?.map((source) => ({
          source,
          frequency: 'NONE', // remocao da configuracao de recorrencia
          onProfileCreation: true, // remocao da configuracao de recorrencia
        })),
      validations: profileTemplate?.validations
        ?.filter(({ category }) => category !== 'onboarding')
        ?.map((rule) => buildPayload(rule)),
    };

    const onboarding = {
      services: Object.keys(profileTemplate?.onboardingServices || {})
        ?.filter((source) => profileTemplate?.onboardingServices[source])
        ?.map((source) => ({ source })),
      validations: profileTemplate?.validations
        ?.filter(({ category }) => category === 'onboarding')
        .map((rule) => buildPayload(rule)),
    };

    const arrayForValidation = onboarding.services.concat(backgroundChecking.services);
    if (!arrayForValidation.length) {
      message.error(
        translate(`${I18N_BASE_PATH}.messages.error.hasNotArrayForValidation`)
      );
      return;
    }

    const payload = {
      type: profileTemplate?.type,
      name: profileTemplate?.name?.trim(),
      webhook: profileTemplate?.webhook?.trim(),
      profileWebhook: profileTemplate?.profileWebhook?.trim(),
      active: true,
      backgroundChecking,
      qsaProfileTemplateId: profileTemplate?.qsaSwitch
        ? profileTemplate?.qsaProfileTemplateId
        : undefined,
      onboardingTemplateId: profileTemplate?.onboardingTemplateId,
      product: product?.source,
      extraSettings: buildExtraSettings(),
    };

    if (profileTemplate.type === 'PF') {
      payload.onboarding = onboarding;
    }

    if (product?.source) {
      const productOnboardingServices =
        product?.template?.onboarding?.services?.map((s) => s.source) || [];

      const servicesNotOnProduct =
        Object.keys(profileTemplate?.onboardingServices || {})
          ?.filter(
            (source) =>
              profileTemplate?.onboardingServices[source] &&
              !productOnboardingServices?.includes(source)
          )
          ?.map((source) => ({
            source,
          })) || [];

      payload.onboarding.services = servicesNotOnProduct;
    }

    if (
      (profileTemplate?.type === 'PJ' && profileTemplate?.qsaSwitch) ||
      profileTemplate?.type === 'PF'
    ) {
      payload.profileRules = profileRules || [];
    }

    if (
      (!profileTemplate?.validations || profileTemplate?.validations.length === 0) &&
      (!profileTemplate?.profileRules || !profileTemplate?.profileRules.length)
    ) {
      showConfirmModal(payload);
      return;
    }

    save(payload);
  }, [
    profileTemplate,
    buildExtraSettings,
    product,
    translate,
    buildPayload,
    post,
    setQuery,
    setProfileTemplate,
  ]);

  const filteredTemplates = useMemo(
    () => allOnboardingTemplates?.filter((t) => t?.type === profileTemplate?.type),
    [allOnboardingTemplates, profileTemplate]
  );

  return (
    <div id="profile-model-confirmation-component">
      <Row className="no-mrg mrg-btm-30 gx-flex-nowrap" align="middle">
        <Col flex={1}>
          <PageTitle
            title={translate(`${I18N_BASE_PATH}.confirmation.pageTitle.title`)}
            subtitle={translate(`${I18N_BASE_PATH}.confirmation.pageTitle.subtitle`)}
          />
        </Col>
        <Col>
          <PriceWidget
            pricePerProfile={summaryPrices?.pricePerProfile}
            pricePerMonth={summaryPrices?.pricePerMonth}
          />
        </Col>
      </Row>
      <Row className="no-mrg mrg-btm-30 gx-flex-nowrap">
        <Title level={4} className="title">
          {translate(`${I18N_BASE_PATH}.profileTemplateInformations.title`)}
        </Title>
      </Row>
      <Row className="mrg-btm-20" style={{ marginTop: -4 }}>
        <Col span={10}>
          <Form.Item
            rules={[
              {
                required: true,
                message: translate(`${I18N_BASE_PATH}.messages.error.hasNotNameDefined`),
              },
            ]}
            name="name"
            label={translate(
              `${I18N_BASE_PATH}.profileTemplateInformations.profileTemplateName`
            )}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label={translate(`${I18N_BASE_PATH}.product.title`)}>
            {product ? (
              <Select className="text-bold" defaultValue={product?.title} disabled />
            ) : (
              <Select
                className="text-bold"
                defaultValue={translate(`${I18N_BASE_PATH}.product.notSelected`)}
                disabled
              />
            )}
          </Form.Item>
        </Col>
        <Col span={5}>
          {filteredTemplates?.length > 0 && (
            <Form.Item
              name="onboardingTemplateId"
              label={translate(`${I18N_BASE_PATH}.onboardingTemplate.label`)}
            >
              <Select
                placeholder={translate(
                  `${I18N_BASE_PATH}.onboardingTemplate.placeholder`
                )}
              >
                {filteredTemplates?.map((template) => {
                  return (
                    <Option key={template?._id} value={template?._id}>
                      {template?.favicon && (
                        <img
                          style={{
                            width: 20,
                            height: 20,
                            objectFit: 'contain',
                            marginRight: 5,
                          }}
                          src={template?.favicon}
                          alt=""
                        />
                      )}
                      {template?.name || template?.description}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
        </Col>
      </Row>
      <Row>
        {query.profileType !== translate(`${I18N_BASE_PATH}.type.company`) && (
          <Col span={8} className="flex">
            <CardOnboarding />
          </Col>
        )}
        <Col
          span={
            query.profileType !== translate(`${I18N_BASE_PATH}.type.company`) ? 8 : 12
          }
          className="flex"
        >
          <CardBackgroundChecking formRef={formRef} />
        </Col>
        <Col
          span={
            query.profileType !== translate(`${I18N_BASE_PATH}.type.company`) ? 8 : 12
          }
          className="flex"
        >
          <CardComplianceRules />
        </Col>
      </Row>
      <Footer
        // hideCancel
        buttonCancelTitle={translate(
          `${I18N_BASE_PATH}.confirmation.footer.buttonCancelTitle`
        )}
        onCancel={() => history.goBack()}
        buttonSubmitTitle={
          loadingSave
            ? translate(`${I18N_BASE_PATH}.confirmation.footer.buttonSubmitTitle.loading`)
            : translate(`${I18N_BASE_PATH}.confirmation.footer.buttonSubmitTitle.default`)
        }
        onSubmit={() => onSubmit()}
        loading={loadingSave}
      />
    </div>
  );
};

Confirmation.propTypes = {
  formRef: PropTypes.shape().isRequired,
};

export default Confirmation;
